<template>
  <div class="detailed-project">
    <div :class="{ card }" class="mb-3">
      <div v-if="!project.uuid" class="text-center p-5"><i>Загрузка...</i></div>
      <div v-if="project.uuid" :class="{ 'card-body': card }">
        <div v-if="backButton" class="form-group">
          <button @click="$router.back()" class="btn btn-link" type="button">
            <i class="bi-arrow-return-left"></i>
            Обратно
          </button>
        </div>
        <rules-confirmed-modal :show="profile.primaryMarketAllow"/>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="text-muted">
              оферта ID {{ project.paymentCode }}
            </div>
            <div id="project-photos" class="carousel slide mt-3">
              <div class="carousel-caption">
                <span class="badge badge-secondary">{{ projectStatus }}</span>
              </div>
              <ol v-if="project.general.photoUrls.length > 1" class="carousel-indicators">
                <li v-for="(_, index) in project.general.photoUrls"
                    data-target="#project-photos"
                    :data-slide-to="index"
                    :class="{ active: index === 0 }"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div v-for="(photoUrl, index) in project.general.photoUrls"
                     class="carousel-item"
                     :class="{ active: index === 0 }"
                     :style="`background-image: url('${ photoUrl }');`">&nbsp;
                </div>
              </div>
              <a v-if="project.general.photoUrls.length > 1"
                 class="carousel-control-prev"
                 href="#project-photos"
                 role="button"
                 data-slide="prev"
              >
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a v-if="project.general.photoUrls.length > 1"
                 class="carousel-control-next"
                 href="#project-photos"
                 role="button"
                 data-slide="next"
              >
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
            <div class="mt-5">
              <div class="clearfix">
                <div class="float-left">
                  <span class="text-muted money-caption">Набрано</span>
                  <money :money="project.loan.investedSum"/>
                </div>
                <div class="float-right text-right">
                  <span class="text-muted money-caption">Цель</span>
                  <money :money="project.loan.loanSum"/>
                </div>
              </div>
              <div class="progress mt-2 mb-2">
                <div class="progress-bar bg-success"
                     role="progressbar"
                     :style="{ width: (project.loan.investedSum.amount / project.loan.loanSum.amount * 100) + '%' }"
                     :aria-valuenow="Math.round(project.loan.investedSum.amount / project.loan.loanSum.amount * 100)"
                     aria-valuemin="0"
                     aria-valuemax="100"
                ></div>
              </div>
              <div class="clearfix">
                <div class="float-left">
                  <span class="text-muted money-caption">Инвесторов</span>
                  {{ project.loan.countInvestors }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mt-3 mt-lg-0">
            <div class="block-general">
              <div class="row">
                <div class="col-12 col-md-9">
                  <div class="h2">{{ project.general.name }}</div>
                  <div class="card-subtitle text-muted">{{ project.general.industry }}</div>
                </div>
                <div class="col-12 col-md-3 mt-3 mt-md-0">
                  <span class="h5 text-success">{{ project.general.mortgageRank }}</span>
                  <public-link to="/page/policy-risks"
                               class="small text-muted d-md-block ml-1 ml-md-0 link-mortgage-rank"
                               target="_blank"
                  >
                    Рейтинг
                  </public-link>
                  <date-format :date="project.loan.maturityDate"
                               class="text-muted mt-md-2 d-md-block float-right float-md-none"/>
                </div>
              </div>
              <hr>
              <dl>
                <dt>Ставка:</dt>
                <dd>{{ project.loan.interestRate ? (Math.round(project.loan.interestRate * 10) / 10) + '%' : '' }}</dd>
                <dt>Срок:</dt>
                <dd>{{ project.loan.initialTerm }} мес.</dd>
                <dt>Тип объекта:</dt>
                <dd>{{ project.loan.objectType }}</dd>
                <dt>LTV:</dt>
                <dd>
                  {{ project.loan.ltv ? (Math.round(project.loan.ltv * 10) / 10) + '%' : '' }}
                  <c-tooltip
                      text="Коэффициент «кредит/залог» (LTV) — отношение суммы запрашиваемого кредита к стоимости имущества, предоставляемого заемщиком в залог."
                      class="ml-5"/>
                </dd>
                <dt>Тип графика:</dt>
                <dd>{{ paymentScheduleType }}</dd>
                <dt>Тип сделки:</dt>
                <dd>{{ project.type === ProjectType.PROJECT ? 'Проект' : 'Займ' }}</dd>
                <div v-if="projectDividendFee">
                  <dt>Комиссия за управление залогом:</dt>
                  <dd>{{ projectDividendFee }} %</dd>
                </div>
              </dl>
            </div>
            <div v-if="project.loan.noProxy" class="card border-info">
              <div class="card-body">
                <p>
                  Доверенность не нужна. Ипотека в силу закона.
                </p>
              </div>
            </div>
            <div v-if="investmentEnabled" class="my-5 block-invest">
              <div v-if="lenderVerified === false" class="card border-info">
                <div class="card-body">
                  Для возможности инвестировать необходимо
                  <router-link :to="{ name: 'authorization' }" class="font-weight-bold">пройти авторизацию</router-link>
                </div>
              </div>
              <div v-if="lenderVerified && investmentAllow === false" class="card border-info">
                <div class="card-body">
                  В Вашем аккаунте отключена возможность инвестирования.<br>
                  Для более детальной информации обратитесь в службу технической поддержки:
                  <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                </div>
              </div>
              <div v-if="lenderVerified && primaryMarketAllow === false && !project.loan.noProxy" class="card border-info">
                <div class="card-body">
                  <p>
                    Для инвестирования на первичном рынке необходимо предоставить
                    доверенность в нотариальной форме, выданную на имя ООО Лендли,
                    как управляющего залогом на заключение договора ипотеки
                    в качестве залогодержателя, его государственную регистрацию
                    и последующее управление залогом.
                  </p>
                  <p>
                    Для получения шаблона доверенности просим написать на почту
                    <a  href="mailto:support@lendly.ru">support@lendly.ru</a>
                  </p>
                  <router-link :to="{ name: 'profile' }" type="button" class="btn btn-success mt-2">
                    Добавить доверенность
                  </router-link>
                </div>
              </div>
              <div v-if="lenderVerified && rulesConfirmed === false" class="card border-info">
                <div class="card-body">
                  Пожалуйста
                  <router-link :to="{ name: 'rules-confirmation' }" class="text-nowrap">ознакомьтесь с новыми правилами
                    платформы
                  </router-link>
                </div>
              </div>
              <div v-else-if="isRegistration && !(maxInvestmentAllow)" class="card border-info">
                <div class="card-body">
                  Минимальная сумма набрана, идет регистрация залога
                </div>
              </div>
              <div v-else-if="lenderVerified && (investmentAvailable === false || balanceEnough === false)"
                   class="card border-info">
                <div v-if="balanceEnough === false" class="card-body">
                  Недостаточно средств на балансе для совершения инвестиции.<br>
                  Минимальная сумма инвестиции:
                  <money :money="minInvestmentSum"/>
                  <br>
                </div>
                <div v-else-if="profile.investmentLimit && profile.investmentLimit.amount === 0" class="card-body">
                  Вы достигли лимита для инвестирования в год.<br>
                  Чтобы увеличить этот лимит обратитесь в службу технической поддержки:
                  <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                </div>
                <div v-else-if="investmentAvailableAmount < minInvestment" class="card-body">
                  <div class="card-body">
                    Минимальная сумма набрана, идет регистрация залога
                  </div>
                </div>
                <div v-else class="card-body">
                  Недостаточный лимит для покупки, осталось:
                  <money :money="investmentAvailableSum"/>
                  <br>
                  Чтобы увеличить этот лимит обратитесь в службу технической поддержки:
                  <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                </div>
              </div>
              <div v-if="this.lenderReservation.length !== 0" class="card border-info">
                <div class="card-body">
                  Зарезервированная сумма:
                  <money :money="lenderReservation[0].reservationRemain"/>
                </div>
                <div class="card-body">
                  <b>Обратите внимание:</b> для успешной инвестиции в проект, необходимо осуществить единовременный платеж на всю зарезервированную сумму.
                  В противном случае, ваша резервация будет аннулирована.
                  Пожалуйста, удостоверьтесь, что сумма вашей инвестиции соответствует зарезервированной, чтобы избежать утраты резервации.
                </div>
              </div>
              <form
                  v-if="lenderVerified && investmentAllow && investmentAvailable && balanceEnough && (primaryMarketAllow || project.loan.noProxy)  && rulesConfirmed && (!isRegistration || maxInvestmentAllow)"
                  @submit="invest">
                <div class="form-group">
                  <div class="input-group">
                    <input-money v-model="investmentAmount"
                                 @keyup="validateInvestmentAmount"
                                 @blur="validateInvestmentAmount"
                                 :min="minInvestment"
                                 :max="investmentAvailableAmount"
                                 class="form-control"
                                 placeholder="Введите сумму"
                                 required/>
                    <div class="input-group-append">
                      <button type="submit" class="btn btn-success">Инвестировать</button>
                    </div>
                  </div>
                  <input-errors :errors="errors.investmentAmount"/>
                  <div class="form-group mt-2">
                    <span class="text-muted">Доступно:</span>
                    <money :money="investmentAvailableSum" class="ml-1"/>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="mt-3 mt-lg-4 block-additional">
          <ul class="nav nav-tabs mb-3" role="tablist">
            <li v-if="isFundRaising && secondaryMarket" class="nav-item" role="presentation">
              <a class="nav-link active"
                 id="offers-tab"
                 data-toggle="tab"
                 href="#offers"
                 role="tab"
                 aria-controls="offers"
                 aria-selected="true"
              >
                Вторичный рынок
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a :class="{ active: !isFundRaising || !secondaryMarket }" class="nav-link"
                 id="common-tab"
                 data-toggle="tab"
                 href="#common"
                 role="tab"
                 aria-controls="common"
                 aria-selected="false"
              >
                Общая информация
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link"
                 id="collaterals-tab"
                 data-toggle="tab"
                 href="#collaterals"
                 role="tab"
                 aria-controls="collaterals"
                 aria-selected="false"
              >
                Обеспечение
              </a>
            </li>
            <li v-if="project.financials" class="nav-item" role="presentation">
              <a class="nav-link"
                 id="financials-tab"
                 data-toggle="tab"
                 href="#financials"
                 role="tab"
                 aria-controls="financials"
                 aria-selected="false"
              >
                Финансовая <span class="d-none d-lg-inline-block">информация</span>
              </a>
            </li>
            <li v-if="project.documents" class="nav-item" role="presentation">
              <a class="nav-link"
                 id="documents-tab"
                 data-toggle="tab"
                 href="#documents"
                 role="tab"
                 aria-controls="documents"
                 aria-selected="false"
              >
                Документы
              </a>
            </li>
            <li v-if="project.documents" class="nav-item" role="presentation">
              <a class="nav-link"
                 id="payment-schedule-tab"
                 data-toggle="tab"
                 href="#payment-schedule"
                 role="tab"
                 aria-controls="payment-schedule"
                 aria-selected="false"
              >
                График платежей
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div v-if="isFundRaising && secondaryMarket" class="tab-pane fade show active" id="offers" role="tabpanel"
                 aria-labelledby="offers-tab">
              <project-offers :project-uuid="project.uuid" :buy="buy"/>
            </div>
            <div :class="{ active: !secondaryMarket || !isFundRaising }" class="tab-pane fade show" id="common"
                 role="tabpanel" aria-labelledby="common-tab">
              <div class="block-common-info">
                <div class="h4">{{ project.general.name }}</div>
                <div v-if="project.general.siteUri">
                  <i class="bi-link-45deg"></i>
                  <a :href="project.general.siteUri" target="_blank">
                    {{ siteUrlHost }}
                  </a>
                </div>
                <div class="h6">Описание</div>
                <div v-html="project.general.projectFeatures"></div>
                <div class="h6">Цель займа</div>
                <div v-html="project.general.loanPurpose"></div>
                <div class="h6">Результаты проверки</div>
                <div v-if="project.checks.checkBankruptcyLink || project.checks.checkBankruptcy" class="check">
                  <div class="check__name">Проверка на банкротство</div>
                  <div>{{ project.checks.checkBankruptcy }}</div>
                  <div>
                    <a :href="project.checks.checkBankruptcyLink" target="_blank">ссылка</a>
                  </div>
                </div>
                <div v-if="project.checks.checkCourt || project.checks.checkCourtLink" class="check">
                  <div class="check__name">Суды</div>
                  <div>{{ project.checks.checkCourt }}</div>
                  <div >
                    <a :href="project.checks.checkCourtLink" target="_blank">ссылка</a>
                  </div>
                </div>
                <div v-if="project.checks.checkEnforcementProceedings || project.checks.checkEnforcementProceedingsLink" class="check">
                  <div class="check__name">Исполнительное производство</div>
                  <div>{{ project.checks.checkEnforcementProceedings }}</div>
                  <div>
                    <a :href="project.checks.checkEnforcementProceedingsLink" target="_blank">ссылка</a>
                  </div>
                </div>
                <div v-if="project.checks.checkRegister || project.checks.checkRegisterLink" class="check">
                  <div class="check__name">Реестр не добросовестных поставщиков</div>
                  <div>{{ project.checks.checkRegister }}</div>
                  <div>
                    <a :href="project.checks.checkRegisterLink" target="_blank">ссылка</a>
                  </div>
                </div>
                <div  v-if="project.checks.checkAuction || project.checks.checkAuctionLink" class="check">
                  <div class="check__name">Участие в торгах</div>
                  <div>{{ project.checks.checkAuction }}</div>
                  <div>
                    <a :href="project.checks.checkAuctionLink" target="_blank">ссылка</a>
                  </div>
                </div>
                <div class="check" v-for="check in project.checks.checks">
                  <div class="check__name">{{ check.name }}</div>
                  <div>{{ check.value }}</div>
                  <div class="">
                    <a v-for="link in check.links" :href="link" target="_blank">ссылка, </a>
                  </div>
                </div>
                <div v-if="project.general.companyFiles.length > 0">
                  <div class="h6">Вложения</div>
                  <div v-for="document in project.general.companyFiles" class="mt-2">
                    <a :href="document.url" target="_blank">
                      <i class="bi-file-earmark-arrow-down"></i>
                      {{ document.name }}
                    </a>
                  </div>
                </div>
                <div v-if="project.general.companyLinks">
                  <div class="h6">Ссылки</div>
                  <div v-html="project.general.companyLinks"></div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="collaterals" role="tabpanel" aria-labelledby="collaterals-tab">
              <div class="block-collaterals">
                <div class="h6">Залог недвижимости</div>
                <dl v-for="collateral in project.collaterals">
                  <dt>Тип объекта</dt>
                  <dd>{{ project.loan.objectType }}</dd>
                  <dt>Место расположения объекта</dt>
                  <dd>{{ collateral.address }}</dd>
                  <dt>Площадь объекта</dt>
                  <dd>{{ collateral.square }} м<sup>2</sup></dd>
                  <dt>Дата приобретения</dt>
                  <dd>
                    <date-format :date="collateral.purchaseDate"/>
                  </dd>
                  <dt>Основание приобретения</dt>
                  <dd>{{ collateral.purchaseReason }}</dd>
                  <dt>Оценочная стоимость</dt>
                  <dd>
                    <money :money="collateral.cost"/>
                  </dd>
                  <dt>LTV при целевой сумме</dt>
                  <dd>{{ collateral.ltv }}</dd>
                  <dt v-if="collateral.comment">Комментарий по залогу</dt>
                  <dd v-if="collateral.comment">{{ collateral.comment }}</dd>
                </dl>
                <div class="h6">Поручительство</div>
                <div v-if="project.guarantors.length === 0">
                  <i>Нет</i>
                </div>
                <dl v-for="guarantor in project.guarantors">
                  <dt>{{ guarantor.fullName }}</dt>
                  <dd>Доля в УК: {{ guarantor.share ? (Math.floor(guarantor.share * 10) / 10) + "%" : "-" }}</dd>
                </dl>
              </div>
            </div>
            <div v-if="project.financials" class="tab-pane fade" id="financials" role="tabpanel"
                 aria-labelledby="financials-tab">
              <div class="block-financials">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <dl>
                      <dt>Валюта баланса</dt>
                      <dd>
                        <money :money="project.financials.coreAssets"/>
                      </dd>
                      <dt>Собственный капитал</dt>
                      <dd>
                        <money :money="project.financials.equityIncluding"/>
                      </dd>
                      <dt>Объем кредитных обязательств</dt>
                      <dd>
                        <money :money="project.financials.amountOfCreditObligations"/>
                      </dd>
                    </dl>
                  </div>
                  <div class="col-12 col-md-6">
                    <dl>
                      <dt>Выручка</dt>
                      <dd>
                        <money :money="project.financials.revenue"/>
                      </dd>
                      <dt>Чистая прибыль</dt>
                      <dd>
                        <money :money="project.financials.netProfit"/>
                      </dd>
                      <dt>Количество сотрудников</dt>
                      <dd>{{ project.financials.numberOfEmployeesAtTheMoment }}</dd>
                    </dl>
                  </div>
                </div>
                <div class="h6">Комментарии</div>
                <div v-html="project.financials.comment"></div>
              </div>
            </div>
            <div v-if="project.documents" class="tab-pane fade" id="documents" role="tabpanel"
                 aria-labelledby="documents-tab">
              <div class="block-documents">
                <a v-for="document in project.documents" :href="document.url" target="_blank">
                  {{ document.name }}<br>
                  <img alt="PDF" src="@/assets/img/pdf_icon.png">
                </a>
              </div>
            </div>
            <div v-if="project.loan.paymentSchedule" class="tab-pane fade" id="payment-schedule" role="tabpanel"
                 aria-labelledby="payment-schedule-tab">
              <div class="block-payment-schedule">
                <div class="row block-payment-schedule__row">
                  <div class="col-xl-2 col-lg-2 col-md-3 small text-muted">
                    Дата платежа
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3 small text-muted">
                    Выплата процентов
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3 small text-muted">
                    Выплата основного долга
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3 small text-muted">
                    Дата оплаты
                  </div>
                </div>
                <div v-for="(item) in project.loan.paymentSchedule.payments" class="row block-payment-schedule__row"
                     :class="this.isPaymentOverdue(item) && item.paid ? 'payment-paid-late' :
                     (!this.isPaymentOverdue(item) && item.paid ? 'payment-paid' :
                     (this.isPaymentOverdue(item) ? 'payment-overdue' : ''))">
                  <div class="col-xl-2 col-lg-2 col-md-3">
                    <date-format :date="item.paymentDate"/>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3">
                    <money :money="item.interestPayment"/>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3">
                    <money :money="item.principalRepayment"/>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3">
                      <span v-if="item.paidAt">
                          <date-format :date="item.paidAt"/>
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import {RulesConfirmedModal} from "@/components/common";
import {Money as ApiMoney, PaymentScheduleType, ProjectStatus, TransactionType} from "@/utils/api-client";
import {createFeeSettingsProvider, createInvestmentManager, createProjectProvider} from "@/utils/di";
import moment from "moment";
import {formatAmount} from "@/components/common";
import CTooltip from "./CTooltip";
import DateFormat from "./DateFormat";
import InputErrors from "./InputErrors";
import InputMoney from "./InputMoney";
import Money from "./Money";
import Processing from "./Processing";
import ProjectOffers from "./ProjectOffers";
import PublicLink from "./PublicLink";
import RouterLink from "vue-router"
import {ProjectType} from "@/utils/api-client/enums/ProjectType";

export default {
  name: "DetailedProject",
  components: {
    RulesConfirmedModal,
    PublicLink,
    CTooltip,
    DateFormat,
    InputErrors,
    InputMoney,
    Money,
    Processing,
    ProjectOffers,
    RouterLink
  },
  inject: ["profile"],
  props: {
    uuid: {
      type: String,
      required: true
    },
    backButton: {
      type: Boolean,
      default: false
    },
    investment: {
      type: Boolean,
      default: false
    },
    buy: {
      type: Boolean,
      default: true
    },
    card: {
      type: Boolean,
      default: true
    },
    secondaryMarket: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      project: {},
      investmentAmount: null,
      errors: {},
      processing: false,
      feeSettings: null
    };
  },
  async mounted() {
    const provider = await createProjectProvider();
    this.project = await provider.getOne(this.uuid);
    const feeSettingsProvider = await createFeeSettingsProvider();
    this.feeSettings = await feeSettingsProvider.getList({projectUuids: [await this.project.uuid]});
  },
  methods: {
    isPaymentOverdue(payment){
        if (this.project.status === ProjectStatus.PAID) return false;

        const paymentDate = moment(moment(payment.paymentDate).format('Y-MM-DD'));

        let paidAt;
        if (!payment.paidAt) {
            paidAt = moment(moment().format('Y-MM-DD'))
        } else {
            paidAt = moment(moment(payment.paidAt).format('Y-MM-DD'));
        }
        paidAt = paidAt.subtract(3, 'days');
        return paidAt.isAfter(paymentDate);
    },
    validateInvestmentAmount() {
      const errors = [];
      if (this.investmentAmount < this.minInvestment) {
        errors.push(`Минимальная сумма инвестиции ${formatAmount(this.minInvestment)} руб.`)
      }
      if (this.investmentAmount > this.investmentAvailableAmount) {
        errors.push(`Максимальная сумма инвестиции ${formatAmount(this.investmentAvailableAmount)} руб.`)
      }
      this.errors.investmentAmount = errors;
      return errors.length === 0;
    },
    async invest(e) {
      e.preventDefault();
      if (this.validateInvestmentAmount()) {
        this.processing = true;
        try {
          const manager = await createInvestmentManager();
          const result = await manager.createConfirmation(
              this.project.uuid,
              new ApiMoney(this.investmentAmount * 100, this.profile.balance.currencyCode)
          );
          await this.$router.push({
            name: "investmentConfirmation",
            params: {uuid: this.project.uuid, confirmationUuid: result.uuid, step: 1}
          });
        } finally {
          this.processing = false;
        }
      }
    }
  },
  computed: {
    lenderVerified() {
      return this.profile.lenderVerified;
    },
    maxInvestmentAllow() {
      return this.profile.maxInvestmentAllow;
    },
    ProjectType() {
      return ProjectType;
    },
    isRegistration() {
      if (this.project.loan.collateralRegistrationSum && this.project.loan.collateralRegistrationSum.amount) {
        return (this.project.loan.loanSum.amount - this.project.loan.investedSum.amount) <= this.project.loan.collateralRegistrationSum.amount;
      }
      return false;
    },
    isFundRaising() {
      return this.project.status === ProjectStatus.FUND_RAISING;
    },
    lenderReservation() {
      return this.project.reservations.filter((item) => item.lenderUuid === this.profile.uuid);
    },
    projectStatus() {
      if (this.project.status) {
        if (this.project.status === ProjectStatus.REVIEW || this.project.status === ProjectStatus.FINAL_REVIEW) {
          return "На рассмотрении";
        } else if (this.project.status === ProjectStatus.REVIEW_APPROVED) {
          return "Заполнение информации";
        } else if (this.project.status === ProjectStatus.REVIEW_REJECTED || this.project.status === ProjectStatus.FINAL_REVIEW_REJECTED) {
          return "Отклонено";
        } else if (this.project.status === ProjectStatus.FINAL_REVIEW_APPROVED) {
          return "Публикация проекта";
        } else if (this.project.status === ProjectStatus.FUND_RAISING) {
          let diff = moment(this.project.loan.maturityDate).diff(new Date());
          if (isNaN(diff) || diff < 0) {
            diff = 0;
          }
          const daysLeft = Math.ceil(diff / (60 * 60 * 24 * 1000));
          const reminder = daysLeft % 10;
          const leftCaptions = {1: "Остался"};
          const leftCaption = leftCaptions[reminder] || "Осталось";
          const dayCaptions = {1: "день", 2: "дня", 3: "дня", 4: "дня"};
          const dayCaption = dayCaptions[reminder] || "дней";
          return `${leftCaption} ${daysLeft} ${dayCaption}`;
        } else if (this.project.status === ProjectStatus.FINISHED || this.project.status === ProjectStatus.LOAN_PAYMENT) {
          return "Проинвестирован";
        } else {
          return "Завершен";
        }
      }
    },
    paymentScheduleType() {
      const paymentScheduleTypes = {
        [PaymentScheduleType.MONTHLY_PAYMENTS]: "Проценты ежемесячно",
        [PaymentScheduleType.QUARTERLY_PAYMENTS]: "Ежеквартальный",
        [PaymentScheduleType.PAYMENT_AT_THE_END]: "Оплата в конце"
      };
      if (this.project.loan && this.project.loan.paymentSchedule && paymentScheduleTypes[this.project.loan.paymentSchedule.scheduleType]) {
        return paymentScheduleTypes[this.project.loan.paymentSchedule.scheduleType];
      }
    },
    siteUrlHost() {
      if (this.project.general.siteUri) {
        try {
          return new URL(this.project.general.siteUri).hostname;
        } catch (err) {
          console.log(err);
        }
      }
    },
    investmentEnabled() {
      return this.investment && this.project.status === ProjectStatus.FUND_RAISING;
    },
    investmentAvailable() {
      return this.investmentEnabled && this.investmentAvailableAmount >= this.minInvestment;
    },
    investmentAllow() {
      return this.profile.investmentAllow;
    },
    primaryMarketAllow() {
      return this.profile.primaryMarketAllow;
    },
    rulesConfirmed() {
      return this.profile.isRulesConfirmed;
    },
    balanceEnough() {
      return this.profile.balance.amount >= this.minInvestmentSum.amount;
    },
    investmentAvailableSum() {
      let amount = 0;
      if (this.project.loan && this.project.loan.loanSum && this.project.loan.investedSum) {
        amount = this.project.loan.loanSum.amount - this.project.loan.investedSum.amount;
      }

      if (!this.profile.maxInvestmentAllow) {
        if (this.project.loan.collateralRegistrationSum && this.project.loan.collateralRegistrationSum.amount) {
          if (amount > this.project.loan.collateralRegistrationSum.amount) {
            amount -= this.project.loan.collateralRegistrationSum.amount;
          }
        }
      }

      if (this.project.reservations.length !== 0) {
        this.project.reservations.forEach((item) => {
          if (item.lenderUuid !== this.profile.uuid) {
            amount -= item.reservationRemain.amount;
          }
        })
      }

      if (amount > this.profile.balance.amount) {
        amount = this.profile.balance.amount;
      }

      if (this.profile.investmentLimit && amount > this.profile.investmentLimit.amount) {
        amount = this.profile.investmentLimit.amount;
      }

      return new ApiMoney(amount, this.project.loan.loanSum.currencyCode);
    },

    investmentAvailableAmount() {
      return Math.floor(this.investmentAvailableSum.amount / 100)
    },
    minInvestment() {
      return this.project.loan.minInvestment.amount / 100;
    },
    minInvestmentSum() {
      return new ApiMoney(this.minInvestment * 100, this.project.loan.loanSum.currencyCode);
    },
    projectDividendFee() {
      let fee;
      if (this.feeSettings) {
        this.feeSettings.items.map(item => {
          if (item.transactionType === TransactionType.DIVIDEND_INTEREST_FEE) {
            fee = item.percent;
          }
        })
      }
      return fee;
    }
  }
}
</script>

<style lang="scss">
.detailed-project {
  dl {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

    dt {
      flex: 0 0 30%;
      max-width: 30%;
      color: #6c757d;
      font-weight: normal;
    }

    dd {
      flex: 0 0 70%;
      max-width: 70%;
    }
  }

  .check {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;

    &__name {
      color: #6c757d;
      font-weight: normal;
    }
  }

  #project-photos {
    position: relative;
    width: 100%;

    .carousel-caption {
      position: absolute;
      bottom: -2rem;

      .badge-secondary {
        background: #252c54;
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .carousel-inner {
      .carousel-item {
        height: 20rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .carousel-indicators li {
      color: #21e58a;
      background-color: #21e58a;
    }

    .carousel-control-prev,
    .carousel-control-next {
      color: #21e58a;
    }
  }
  .block-payment-schedule {
    overflow-x: scroll;
    &__row {
      flex-wrap:nowrap;
    }
  }
  .payment-paid {
    background-color: #d2f8d2;
  }

  .payment-paid-late {
    background-color: #ffdb9a;
  }

  .payment-overdue {
    background-color: #ffb6c1;
  }

  .money-caption {
    margin-right: 0.5rem;
  }

  .block-general {
    a.link-mortgage-rank {
      color: #6c757d;
      text-decoration: underline;
    }

    a.link-mortgage-rank:hover {
      text-decoration: none;
    }
  }

  .block-invest {
    .form-group {
      width: 25rem;
      max-width: 100%;
    }
  }

  .block-additional {
    min-height: 15rem;

    .h6 {
      margin-top: 1.5rem;
    }

    dl {
      dt {
        flex: 0 0 35%;
        max-width: 35%;
      }

      dd {
        flex: 0 0 60%;
        max-width: 35rem;

        a {
          float: right;
        }
      }
    }

    p {
      margin-bottom: 0.25em;
    }

    .nav-tabs {
      border: none;

      .nav-link,
      .nav-link:hover,
      .nav-link:focus,
      .nav-link.active {
        position: relative;
        border: none;
        background: none;
      }

      .nav-link {
        color: #000;
        margin-left: 0;
        margin-right: 0;

        @media (min-width: 768px) { // Bootstrap MD
          margin-left: 0.25rem;
          margin-right: 0.25rem;
        }

        @media (min-width: 1040px) {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }

        @media (min-width: 1140px) {
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }

      .nav-link.active {
        color: #21e58a;
      }

      .nav-link.active:before {
        content: "";
        background: #21e58a;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0.2rem;
        width: 100%;
      }
    }

    .block-financials {
      dl {
        dt {
          flex: 0 0 65%;
          max-width: 65%;
        }

        dd {
          flex: 0 0 35%;
        }
      }
    }

    .block-documents {
      a {
        display: inline-block;
        text-align: center;
        margin: 0 0.5rem;

        img {
          height: 6.75rem;
          width: auto;
        }
      }
    }
  }
}
</style>
